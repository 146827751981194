const system = [
  {
    name: '商户通',
    intro: '本地流量、本地权益',
    icon: require('@/assets/images/home/sys-01.jpg')
  },
  // {
  //   name: '绿色积分',
  //   intro: '深化政府合作，开放共赢',
  //   icon: require('@/assets/images/home/sys-02.jpg')
  // },
  {
    name: '碳普惠',
    intro: '深化政府合作，开放共赢',
    icon: require('@/assets/images/home/sys-02.jpg')
  },
  
  {
    name: '工会惠普',
    intro: '激活联名卡',
    icon: require('@/assets/images/home/sys-03.jpg')
  },
  {
    name: '联盟链',
    intro: '机构平等 开放共享',
    icon: require('@/assets/images/home/sys-04.jpg')
  }
]
  
export default system